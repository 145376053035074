//@import 'variables';
@import './cfa-theme';
@import "~bootstrap/dist/css/bootstrap.css";
@import '~font-awesome/css/font-awesome.min.css';

@import '../assets/css/app.css';
@import './globals.scss';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.cfa-nowrap-row {
  width: 100% !important;
  margin: 0 !important;
}

.cfa-12 {
  font-size: 12px;
  line-height: 12px;
}
.row:before, .row:after {display: none !important;}
