@-moz-document url-prefix() {
    html {
        clear: both !important;
        display: inline-block !important;
        height:100%!important;
        margin-top:.01em !important;
        margin-bottom:.01em !important;
    }
}

a {
    text-decoration: none;
}

i.material-icons{
    vertical-align: middle;
    font-size: 24px;
}

/* ---------- Override CSS ---------- */
html,body {
    height:100% !important;
    background: #ffffff;
}

body {
    font-family: "Apercu", sans-serif !important;
    text-shadow: none;
}

a:hover{
    text-decoration:none;
}
:focus {
    outline: -webkit-focus-ring-color auto 0px !important;
    outline: -moz-focus-ring-color auto 0px !important;
    outline: -ms-focus-ring-color auto 0px !important;
}
/* ---------- Override CSS End ---------- */

/* ---------- CUSTOM FONTS CSS ---------- */
@font-face {
    font-family: "Caecilia LT STD";
    src: url("fonts/CaeciliaLTStd-Light.woff");
}

/* remove for now to prevent Apercu permissions error */
/*@font-face {*/
/*    font-family: "Apercu";*/
/*    src: url("fonts/Apercu-Regular.otf") format("opentype");*/
/*}*/

/************ TEXT STYLES CSS ************/
.boldText {
    font-weight:bold !important;
}

.centerText {
    text-align:center;
}

.leftText {
    text-align:left !important;
}

/* ---------- Common CSS End ---------- */

.bs-callout.small-callout {
    font-size: 85%;
    margin-bottom: 0px;
}

.bs-callout {
    padding: 0.5em 1em;
    text-align: left;
    border-left: 3px solid #b71C1C;
    background-color: rgba(105, 97, 88, 0.1);
}

.bs-callout h4 {
    font-weight: bolder;
    margin-top: 0.5em !important;
    margin-bottom: 0;
    font-size: 1.5em;
    display: block;
    padding: 10px;
    color: #b71C1C;
}

.bs-callout p {
    text-align: left;
    margin: 5px;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    padding-bottom: 0.5em;
}

.bs-callout.success {
    border-left: 3px solid #9CC888;
    margin: 0 0 10px 0;
    padding: 20px;
    background-color: rgba(156,200,136,0.1);
    font-size: inherit;
}

.bs-callout.success h4 {
    color: #9CC888;
    font-weight: bolder;
}

.bs-callout.success h4:before {
    font-family: 'Material Icons';
    content: '\E86C';
    padding-right: 5px;
    vertical-align: -10%;
    font-size: inherit;
    color: #9CC888;
}

.bs-callout.warning{
    background-color: rgba(105, 97, 88, 0.2);
    border: none;
    background-color: #f8f8f8;
    padding: 1em;
    color: rgba(105, 97, 88, 1);
}
.bs-callout-outline{
    background-color: rgba(105, 97, 88, 0.2);
    border: 3px double rgba(105, 97, 88, 1);
}

.bs-callout.warning h4{
    font-weight: bolder;
    color: rgba(105, 97, 88, 1);
    text-align: center;
    display: block;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    font-size: 1.7em;
    border-bottom: 1px solid rgba(105, 97, 88, 1);
}

.bs-callout.warning h4:before{
    font-family: 'Material Icons';
    content: '\E002';
    padding-right: 0px;
    vertical-align: 0;
    font-size: inherit;
    font-size: 2.5em;
    color: rgba(105, 97, 88, 1);
    display: block;

}

.bs-callout.info {
    border-left: 3px solid #7aa6de;
    background-color: rgba(122, 166, 222, 0.1);
}

.bs-callout.info h4 {
    font-weight: bolder;
    color: rgba(122, 166, 222, 1);
}

.bs-callout.info h4:before {
    color: #7aa6de;
    font-family: 'Material Icons';
    content: '\E0B9';
    padding-right: 5px;
    vertical-align: -10%;
    font-size: inherit;
}

.bs-callout h4:before {
    color: #b71C1C;
    font-family: 'Material Icons';
    content: '\E5C9';
    padding-right: 5px;
    vertical-align: -10%;
    font-size: inherit;
}

#non-footer {
    min-height: 85%;
    background: #ffffff;
}

.friendly-error {
    font-size: 1.1em;
    margin-top: 0.5em;
}

/********** Fixes blinking/flickering icons and elements **********/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

.material-icons.close-icon {
    font-weight: 100 !important;
    color: rgba(105, 97, 88, 0.7);
}

canvas {
    position: absolute;
    left: 0;
    top: 0;
}

.hidden {
    display: none !important;
    height: 0px;
    overflow: hidden;
}

