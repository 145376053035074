$cfaPrimary: #004F71;
$cfaPrimaryFade: rgba(0, 79, 113, .35);
$cfaSecondary: #3EB1C8;
$cfaSecondaryRed: #AF272F;
$cfaSilver: #5B6770;
$cfaLightBlue: #dcf0f5;

$cfaLightGray: #F5F5F5;
$cfaDarkGray: #5B6770;
$cfaDarkerGray: #485259;
$cfaGray: #efeeed;
$cfaCyanishWhite: #DBF0F5;

$cfaErrorRed: #DD0031;
$cfaWhite: #FFFFFF;
$cfaBlack: #000000;
$cfaLynxWhite: #F7F7F7;
$cfaBlackFade: rgba(3,3,3,0.15);
$cfaWhiteBackground: #F4F8F9;
$cfaPattensBlue: #DBE0E2;
$cfaCinnabar: #E6233F;
$cfaDarkestGray: #353F47;
$cfaLightShadeGray: #8E969C;
$cfaGreen: #249E6B;
$cfaGrayInfoText: #6F7F88;
$cfaGrayShade: #333333;
$cfaSliderBlue: #45b1c7;
$cfaInactiveGray: #8a9ba8;


$understand-red: rgb(221, 0, 51);
$understand-green: rgb(36, 158, 107);
$understand-blue: rgb(62, 177, 200);
$understand-salmon: rgb(250, 147, 112);
$understand-purple: rgb(153, 72, 120);
$understand-orange: rgb(255, 181, 73);
$understand-care-blue: rgb(0, 79, 113);

$understand-light-red: rgba(221, 0, 51, .35);
$understand-light-green: rgba(36, 158, 107, .35);
$understand-light-blue: rgba(62, 177, 200, .35);
$understand-light-salmon: rgba(250, 147, 112, .35);
$understand-light-purple: rgba(153, 72, 120, .35);
$understand-light-orange: rgba(255, 181, 73, .35);
$understand-light-care-blue: rgba(0, 79, 113, .35);

$cfaNormalFont : 'Apercu-Regular';
$cfaBoldFont : 'Apercu-Bold';
